const initialTheme = {
  breakpoints: ['481px', '768px', '992px', '1201px', '1441px'],
  primary: [
    '#EE3932', // 0: Default
    '#e68986', // 1: Chart
    '#eb8c8960', // 2: Light
    '#fcbe06', // 3: Yellow
    '#00a6b7', // 4: Green
    '#59b031', // 5: PlayButton Green
    '#81ca5f', // 6: PlayButton Green Light
    '#008489', // 0: Default
    '#399C9F', // 1: Chart
  ],
  color: [
    '#000000', // 0: Black
    '#ffffff', // 1: White
    '#F7F7F7', // 2: Dropdown Hover
    '#E9E8E8', // 3: Bullet bg
    '#FC5C63', // 4: Active Favorite
    'rgba(0, 0, 0, 0.25)', // 5: Inactive Favorite
    '#273343', // 6: Agent Social Icon
    '#3b5998', // 7: Facebook
    '#55ADEE', // 8: Twitter
    '#25D366', // 9: Whatsapp
    '#dd4b39', // 10: Google Plus
    '#F61C0D', // 11: Youtube
    '#e4405f', // 12: Instagram
    '#E2E2E2', // 13: Range Color
    '#00ACEE', // 14: Github Color
    '#FFCB2B', // 15: Firebase Color
    '#484848', // 16: Link button
  ],
  warning: [
    '#F29100', // 0: Warning
  ],
  success: [
    '#00BB5D', // 0: Success
  ],
  error: [
    '#F9503D', // 0: Error
    'rgba(249, 80, 61, 0.08)', // 1: Error Light Bg
  ],
  text: [
    '#2C2C2C', // 0: Heading
    '#909090', // 1: Meta Text
    '#777777', // 2: Text Info
  ],
  border: [
    '#EBEBEB', // 0: Light border
    '#E8E8E8', // 1: Default Border
    'rgba(57, 151, 247, 0.35)', // 2: Active Light Border
    '#E6E6E6', // 3: Pricing Border
  ],
  boxShadow: [
    'rgba(26, 68, 116, 0.16)', // 0: Card hover
    'rgba(0, 0, 0, 0.16)', // 1: Carousel Button shadow
    'rgb(252, 191, 1, 0.2)', // 2: input shadow
  ],
};

const colorOverrides: any = {
  anto: {
    primary: [
      '#EE3932', // 0: Default
      '#e68986', // 1: Chart
      '#eb8c8960', // 2: Light
      '#fcbe06', // 3: Yellow
      '#00a6b7', // 4: Green
      '#59b031', // 5: PlayButton Green
      '#81ca5f', // 6: PlayButton Green Light
      '#008489', // 0: Default
      '#399C9F', // 1: Chart
    ],
    backgrounds: {
      root:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.26) 0%, rgba(238, 57, 50, 0.28) 24%, rgba(175, 149, 211, 0.67) 100%)', // 0: root
      profileIconBg: '#EE3932',
    },
    buttons: {
      primary: [
        '#EE3932', // background
        '#000', // color
        '#81ca5f',
      ],
      secondary: [
        '#59b031', // background
        '#000', // color
        '#81ca5f',
      ],
    },
    fonts: [
      'Lato, sans-serif', // titles
      'Lato, sans-serif', //body
    ],
  },
  mntp: {
    primary: [
      '#6611F0', // 0: Default
      '#e68986', // 1: Chart
      '#eb8c8960', // 2: Light
      '#fcbe06', // 3: Yellow
      '#00a6b7', // 4: Green
      '#EA583F', // 5: PlayButton Green
      '#81ca5f', // 6: PlayButton Green Light
      '#008489', // 0: Default
      '#399C9F', // 1: Chart
    ],
    backgrounds: {
      root:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.34) 0%, rgba(221, 83, 80, 0.34) 24%, rgba(107, 35, 227, 0.34) 100%)', // 0: root
      lottie: '#6611F2', // 1: Lottie animation
      navbar: 'transparent', // 2: navbar
      profileIconBg: '#fff',
    },
    buttons: {
      primary: [
        '#6611F0', // background
        '#fff', // color
        '#4F19A8', // hover background
      ],
      secondary: [
        '#EA583F', // background
        '#fff', // color
        '#B94431', // hover background
      ],
    },
    fonts: [
      'MintGrotesk, sans-serif', // titles
      'Barlow, sans-serif', //body
    ],
  },
};

const applyThemeOverrides = (defaultTheme: any, overrides: any) => {
  const themeCopy = { ...defaultTheme };
  Object.keys(overrides).forEach((key) => {
    themeCopy[key] = overrides[key];
  });
  return themeCopy;
};

const currentThemeName: string = process.env.REACT_APP_ENV || 'anto';
const currentOverrides = colorOverrides[currentThemeName] || {};

const defaultTheme = applyThemeOverrides(initialTheme, currentOverrides);

export default defaultTheme;
